<template>
  <div>
    <div v-show="showBack" class="back" @click.stop="goBack()">
      <i class="el-icon-arrow-left"></i>
      <span>返回</span>
    </div>
    <div class="page-content">
      <div class="page-sub-title flex flex-center">
        <span class="flex-1">筛选查询</span>
      </div>
      <div>
        <div class="search-item">
          <span class="label">门店名称/简称</span>
          <el-input v-model="searchParams.search" size="small" clearable style="width: 240px" placeholder="请输入门店名称"></el-input>
        </div>
        <div class="search-item">
          <span class="label">省市区</span>
          <el-cascader placeholder="请选择" size="small" :props="props" style="width: 180px" clearable v-model="searchParams.areaCode"></el-cascader>
        </div>
        <div class="search-item">
          <span class="label">所属直销</span>
          <el-select
            v-model="searchParams.salerId"
            filterable
            placeholder="请选择"
            size="small"
            style="width: 160px" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in salerList"
              :key="item.id"
              :label="item.realName"
              :value="item.userId">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <span class="label">产品</span>
          <el-cascader placeholder="请选择" size="small" :options="productList" :props="productProps" clearable v-model="searchParams.productIdArray" style="width: 160px" @change="changeProduct"></el-cascader>
        </div>
        <div class="search-item">
          <span class="label">状态</span>
          <el-select v-model="searchParams.status" placeholder="请选择" size="small" style="width: 160px" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in statusOption"
              :key="item.k"
              :label="item.v"
              :value="item.k">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <span class="label">所属集团</span>
          <el-select v-model="searchParams.parentId" placeholder="请选择" size="small" style="width: 160px" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option label="无" :value="-1"></el-option>
            <el-option
              v-for="item in brandList"
              :key="item.id"
              :label="item.hotelName"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <span class="label">初装定价</span>
          <popover-select-range
            :visible.sync="visible1"
            :option="priceOption"
            :valueStart.sync="searchParams.firstPriceStart"
            :valueEnd.sync="searchParams.firstPriceEnd"
            :value.sync="searchParams.firstPriceSearch"
            unit="元"
          ></popover-select-range>
        </div>
        <div class="search-item">
          <span class="label">年费定价</span>
          <popover-select-range
            :visible.sync="visible2"
            :option="priceOption"
            :valueStart.sync="searchParams.yearPriceStart"
            :valueEnd.sync="searchParams.yearPriceEnd"
            :value.sync="searchParams.yearPriceSearch"
            unit="元/年"
          ></popover-select-range>
        </div>
        <div class="search-item">
          <span class="label">初装赠送短信</span>
          <popover-select-range
            :visible.sync="visible3"
            :option="smsOption"
            :valueStart.sync="searchParams.smsCountStart"
            :valueEnd.sync="searchParams.smsCountEnd"
            :value.sync="searchParams.smsCountSearch"
            unit="条"
          ></popover-select-range>
        </div>
        <div class="search-item">
          <span class="label">是否测试门店</span>
          <el-select v-model="searchParams.testFlag" placeholder="请选择" size="small" style="width: 160px" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option label="正式门店" :value="false"></el-option>
            <el-option label="测试门店" :value="true"></el-option>
          </el-select>
        </div>
        <div class="search-item">
          <span class="label">距离到期天数</span>
          <el-input v-model="searchParams.dueTimeStart" size="small" style="width: 100px" clearable type="text" @input="handleInput($event,'dueTimeStart')"></el-input>
          <span style="margin: 0 16px">至</span>
          <el-input v-model="searchParams.dueTimeEnd" size="small" style="width: 100px" clearable type="text" @input="handleInput($event,'dueTimeEnd')"></el-input>
        </div>
        <div class="search-item">
          <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
          <el-button class="border-btn" plain size="small" @click="reset">重置</el-button>
        </div>
      </div>
      <div class="page-sub-title flex flex-center">
        <span class="flex-1">门店列表</span>
        <el-button type="primary" size="small" @click="goEdit()">+ 新增门店</el-button>
      </div>
      <el-table
        :data="list"
        style="width: 100%"
        border
        header-row-class-name="table-header"
        :cell-style="randerCellStyle"
      >
        <el-table-column
          align="center"
          label="门店名称"
          min-width="120"
          fixed="left"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="showDetail(scope.row)">{{scope.row.hotelName}}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="hotelShortName"
          label="门店简称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="parentName"
          label="所属集团"
        ></el-table-column>
        <el-table-column
          align="center"
          label="门店地址"
          min-width="200"
        >
          <template slot-scope="scope">
            {{scope.row.provinceName}}
            {{scope.row.cityName}}
            {{scope.row.areaName}}
            {{scope.row.hotelAddr}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="contactPerson"
          label="联系人姓名"
          min-width="110"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="contactPhone"
          label="联系人手机号"
          min-width="110"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="salerName"
          label="所属直销"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="systemName"
          label="产品"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="productName"
          label="产品版本"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="firstPrice"
          label="初装定价(元)"
          width="120"
        >
          <template slot-scope="scope">
            <span>{{$commaNum(scope.row.firstPrice)}}</span>
            <el-tooltip effect="dark" :content="`低于初装参考价${$commaNum(scope.row.productInfo.firstPrice)}元`" placement="right" v-if="scope.row.firstPrice < scope.row.productInfo.firstPrice">
              <span class="icon-below"></span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="yearPrice"
          label="年费定价(元/年)"
          width="120"
        >
          <template slot-scope="scope">
            <span>{{$commaNum(scope.row.yearPrice)}}</span>
            <el-tooltip effect="dark" :content="`低于年费参考价${$commaNum(scope.row.productInfo.yearPrice)}元`" placement="right" v-if="scope.row.yearPrice < scope.row.productInfo.yearPrice">
              <span class="icon-below"></span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="初装赠送短信(条)"
          width="130"
        >
          <template slot-scope="scope">
            <span>{{$commaNum(scope.row.smsCount)}}</span>
            <el-tooltip effect="dark" :content="scope.row.smsRemark" placement="right" v-if="scope.row.smsCount > scope.row.productInfo.gifMesNum">
              <span class="icon-warning"></span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="enableTime"
          label="启用时间"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="expireTime"
          label="到期时间"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="dueTime"
          label="距离到期天数"
          width="120"
        >
        <template slot-scope="scope">
          <span>{{scope.row.dueTime}}</span>
          <el-tooltip effect="dark" content="门店即将到期" placement="right" v-if="scope.row.dueTime >= 0 && scope.row.dueTime <= 30">
            <span class="icon-warning-dueTime"></span>
          </el-tooltip>
        </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="delayDays"
          label="延期天数"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          label="状态"
          width="100"
        >
          <template slot-scope="scope">
            <el-dropdown trigger="click" @command="changeStatus($event, scope.row)">
              <span class="status-tag" :class="'status' + scope.row.status">
                {{statusMap[scope.row.status]}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="item in statusOption"
                  :key="item.k"
                  :command="item.k">
                  {{item.v}}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="是否测试门店"
          width="120"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.testFlag !== null">{{scope.row.testFlag === true ? '测试门店' : '正式门店'}}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="操作"
          width="150"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="goEdit(scope.row)">编辑</el-button>
            <el-dropdown trigger="click" @command="handleCommand($event, scope.row)">
              <span class="el-dropdown-link">
                <el-button type="text" style="margin-left: 10px;">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="login" v-if="auth.indexOf('hotel:login:btn') > -1" :class="{'disabled': scope.row.status != 2}">
                  <el-tooltip class="item" :content="reason[scope.row.status]" placement="left" :disabled="scope.row.status == 2">
                    <span>
                      <span class="icon-login"></span>
                      门店登录
                    </span>
                  </el-tooltip>
                </el-dropdown-item>
                <el-dropdown-item command="log">
                  <span class="icon-change"></span>
                  变更记录
                </el-dropdown-item>
                <el-dropdown-item command="recharge">
                  <span class="icon-renew"></span>
                  续期
                </el-dropdown-item>
                <el-dropdown-item command="clear" :class="{'disabled': auth.indexOf('hotel:clear:btn') < 0 || scope.row.clearBizDataFlag}">
                  <el-tooltip class="item" :content="auth.indexOf('hotel:clear:btn') < 0 ? '您没有权限操作，请联系管理员' : '历史已操作过数据清空，不可进行二次操作'" placement="left" :disabled="auth.indexOf('hotel:clear:btn') >= 0 && !scope.row.clearBizDataFlag">
                    <span>
                      <span class="icon-login"></span>
                      清空数据
                    </span>
                  </el-tooltip>
                </el-dropdown-item>
                <el-dropdown-item command="delete" v-if="scope.row.testFlag" :class="{'disabled': auth.indexOf('hotel:delete:btn') < 0}">
                  <el-tooltip class="item" content="您没有权限操作，请联系管理员" placement="left" :disabled="auth.indexOf('hotel:delete:btn') >= 0">
                    <span>
                      <span class="icon-login"></span>
                      删除测试门店
                    </span>
                  </el-tooltip>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        class="text-right m-t-16"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="pageSizes"
        :current-page="pageParams.pageNo"
        :page-size="pageParams.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems">
      </el-pagination>
    </div>

    <recharge-hotel-dialog :visible="rechargeVisible" :info="rechargeData" @close="hideRecharge" @confirm="confirmRecharge"></recharge-hotel-dialog>
    <detail-hotel-dialog :visible="detailVisible" :info="detailData" :productList="productList" @close="hideDetail"></detail-hotel-dialog>
    <change-log-dialog :visible="logVisible" :hotelId="hotelId" @close="hideLog"></change-log-dialog>
    <change-hotel-status-dialog :visible="changeVisible" :info="changeData" @close="hideChange" @confirm="confirmChange"></change-hotel-status-dialog>

    <!-- 清空业务数据 弹窗 -->
    <el-dialog
      :visible.sync="clearVisible"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="清空业务数据"
    >
      <div>
        <div class="c-red">
          <div>说明：</div>
          <div>1、业务数据清空只有一次机会，清空后不可再次操作清空，请仔细确认后再进行清空</div>
          <div>2、清空后的数据无法恢复</div>
        </div>
        <div class="flex mt-20">
          <span style="flex-shrink:0"><span class="c-red">*</span>清空数据对象：</span>
          <div>
            <el-checkbox-group v-model="clearData" disabled>
              <el-checkbox label="clue">线索数据</el-checkbox>
              <el-checkbox label="preorder">销售机会数据</el-checkbox>
              <el-checkbox label="order">宴会订单数据</el-checkbox>
              <el-checkbox label="customer">客户数据</el-checkbox>
              <el-checkbox label="message">消息通知</el-checkbox>
              <el-checkbox label="zeroOrder">零点订单数据</el-checkbox>
              <el-checkbox label="approve">审批数据</el-checkbox>
              <el-checkbox label="visitor">来访登记</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button size="small" @click="clearVisible = false">取消</el-button>
        <el-button type="primary" size="small" @click="handleClear" :disabled="clearData.length == 0">清空</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { pageMixin } from '@/mixins/pageMixin'
import { get_key_value, get_area_list, check_config_key } from '@/api/common'
import { get_product_list, post_getSaleUserList } from "@/api/product";
import { get_hotel_list, bread_brandList, delete_hotel, clear_hotel_data } from '@/api/hotel'
// import { get_user_list } from '@/api/user'
import rechargeHotelDialog from './component/recharge-hotel-dialog'
import detailHotelDialog from './component/detail-hotel-dialog'
import changeLogDialog from './component/change-log-dialog'
import changeHotelStatusDialog from './component/change-hotel-status-dialog'
import { pc_url } from '@/config/index'
import popoverSelectRange from '@/components/popover-select-range'

export default {
  name: 'hotel-list',
  mixins: [pageMixin],
  components: {
    rechargeHotelDialog,
    detailHotelDialog,
    changeLogDialog,
    changeHotelStatusDialog,
    popoverSelectRange
  },
  data() {
    return {
      statusOption: [],
      statusMap: {},
      salerList: [],
      productList: [],
      searchParams: {
        search: '',
        areaCode: [],
        salerId: '',
        productIdSet: [],
        status: '',
        parentId: '',
        firstPriceStart: '',
        firstPriceEnd: '',
        yearPriceStart: '',
        yearPriceEnd: '',
        smsCountStart: '',
        smsCountEnd: '',
        testFlag: '',
        //
        productIdArray: [],
        firstPriceSearch: '',
        yearPriceSearch: '',
        smsCountSearch: '',
        dueTimeStart:'',
        dueTimeEnd:'',
      },
      rechargeVisible: false,
      rechargeData: {},
      detailVisible: false,
      detailData: {},
      logVisible: false,
      changeVisible: false,
      changeData: {},
      hotelId: '',
      showBack: false,
      auth: [], // 权限
      reason: {
        1: '门店未到启用时间，无法登录',
        2: '',
        3: '门店已下线，无法登录',
        4: '门店异常，无法登录'
      },
      productProps: {
        label: 'productName',
        value: 'productId',
        children: 'productList',
        expandTrigger: 'hover'
      },
      brandList: [],
      priceOption: [
        {
          k: '',
          v: '全部',
          value: []
        },
        {
          k: 1,
          v: '0 - 5,000',
          value: ['0', '5000']
        },
        {
          k: 2,
          v: '5,000 - 10,000',
          value: ['5001', '10000']
        },
        {
          k: 3,
          v: '10,000 - 30,000',
          value: ['10001', '30000']
        },
        {
          k: 4,
          v: '30,000 - 50,000',
          value: ['30001', '30000']
        }
      ],
      smsOption: [
        {
          k: '',
          v: '全部',
          value: []
        },
        {
          k: 1,
          v: '500',
          value: ['500', '500']
        },
        {
          k: 2,
          v: '1000',
          value: ['1000', '1000']
        },
        {
          k: 3,
          v: '2000',
          value: ['2000', '2000']
        },
        {
          k: 4,
          v: '10000',
          value: ['10000', '10000']
        },
      ],
      visible1: false,
      visible2: false,
      visible3: false,
      clearVisible: false,
      clearData: ['clue', 'preorder', 'order', 'customer', 'message','zeroOrder','approve','visitor'],
      dialogData: {}, // 弹窗上的数据
    }
  },
  computed: {
    props() {
      return {
        lazy: true,
        label: 'name',
        value: 'code',
        expandTrigger: 'hover',
        lazyLoad: async (node, resolve) => {
          console.log(node)
          const { level, data } = node;
          let params = {}
          if (level == 1) {
            params = {
              areaLevel: "PROVINCE",
              code: data.code
            }
          } else if (level == 2) {
            params = {
              areaLevel: "CITY",
              code: data.code
            }
          }
          let res = []
          if (level == 0 || (data.code && (level == 1 || level == 2))) {
            res = await get_area_list(params)
          }
          res = [{
            name: '全部',
            code: '',
            leaf: true
          }].concat(res)
          if (level == 2) {
            res = res.map(o => {
              return {
                ...o,
                leaf: true
              }
            })
          }
          resolve(res)
        }
      }
    }
  },
  activated() {
    if (this.$store.state.page.keepAlive.indexOf('hotel-list') < 0 || this.list.length == 0) {
      Object.assign(this.$data, this.$options.data())
      this.originSearchParams = this.$deepClone(this.searchParams)
      this.getAuth()
      this.getProduct()
      this.getOption()
      this.getSaler()
      if (this.$route.query && JSON.stringify(this.$route.query) != '{}') {
        this.showBack = true
        this.searchParams.salerId = this.$route.query.marketId
      }
      this.getList()
    }
  },
  methods: {
    handleInput(val,param){
      val = val.replace(/[^-\d]/g,'')
      this.searchParams[param] = val
    },
    handleSearch(){
      if (Number(this.searchParams.dueTimeStart) <= Number(this.searchParams.dueTimeEnd)) {
        this.search()
      } else {
        this.$message.warning('请输入正确的筛选条件')
      }
    },
    getAuth() {
      check_config_key({
        configKeyList: ['hotel:login:btn', 'hotel:clear:btn', 'hotel:delete:btn'],
        hotelId: 0
      }).then(res => {
        this.auth = res && res.filter(o => { return !!o.hasConfigKey })
                              .map(v => { return v.configKey })
      })
    },
    getProduct() {
      get_product_list()
        .then(res => {
          let arr = [{
            productName: '全部',
            productId: '',
            productList: [],
          }].concat(res || [])
          this.productList = arr.map(o => {
            let productList = o.productId ? [{
              versionName: '全部',
              productId: (o.productList || []).map(vv => { return vv.productId }).join(',')
            }].concat(o.productList || []) : (o.productList || [])
            return {
              ...o,
              productList: o.productId ? productList.map(v => {
                return {
                  ...v,
                  productName: v.versionName
                }
              }) : null
            }
          })
        })
    },
    getOption() {
      get_key_value({
        type: 'HOTEL_STATUS'
      }).then(res => {
        this.statusOption = res ? res.records : []
        this.statusMap = this.$arrayToMap(this.statusOption)
      })
      bread_brandList({
        page: 1,
        size: 999
      }).then(res => {
        this.brandList = res.records
      })
    },
    async getSaler() {
      const res = await post_getSaleUserList({
        size: 1000,
        pageNo: 1,
      })
      this.salerList = res ? res.records : []
    },
    changeProduct(value) {
      if (value[1]) {
        this.searchParams.productIdSet = value[1].split(',')
      } else {
        this.searchParams.productIdSet = []
      }
      console.log(this.searchParams.productIdSet)
    },
    async getList() {
      let params = {
        ...this.pageParams,
        ...this.searchParams
      }
      if (params.areaCode) {
        if (params.areaCode[2]) {
          params.areaCode = params.areaCode[2]
        } else if (params.areaCode[1]) {
          params.areaCode = params.areaCode[1]
        } else {
          params.areaCode = params.areaCode[0]
        }
      } else {
        params.areaCode = ''
      }
      delete params.productIdArray
      delete params.firstPriceSearch
      delete params.yearPriceSearch
      delete params.smsCountSearch
      const res = await get_hotel_list(params)
      console.log(res)
      this.list = res ? res.records : []
      this.totalItems = res ? res.total : 0
    },
    randerCellStyle({ row, column }) {
      if (
        (row.firstPrice < row.productInfo.firstPrice && column.property == 'firstPrice') ||
        (row.yearPrice < row.productInfo.yearPrice && column.property == 'yearPrice')
      ) {
        return {
          background: '#FCF8F8'
        }
      } else {
        return {}
      }
    },
    goEdit(row) {
      this.$router.push({
        name: 'hotel-edit',
        query: {
          id: row ? row.id : ''
        }
      })
    },
    changeStatus(value, row) {
      this.changeData = {
        hotelId: row.id,
        status: value,
        statusOldName: this.statusMap[row.status],
        statusName: this.statusMap[value],
        hotelName: row.hotelName
      }
      this.changeVisible = true
    },
    handleCommand(value, row) {
      switch (value) {
        case 'login':
          if (row.status == 2) {
            // window.open(`http://192.168.3.180:8083/#/login?token=${this.$store.state.token}&hotelId=${row.id}`)
            window.open(`${pc_url}/#/login?token=${this.$store.state.token}&hotelId=${row.id}`)
          } else {
            return
          }
          break
        case 'log':
          this.hotelId = row.id
          this.logVisible = true
          break
        case 'recharge':
          this.rechargeVisible = true
          this.rechargeData = row
          break
        case 'clear':
          if (this.auth.indexOf('hotel:clear:btn') >= 0 && !row.clearBizDataFlag) {
            this.clearVisible = true
            this.dialogData = row
          }
          break
        case 'delete':
          if (this.auth.indexOf('hotel:delete:btn') < 0) {
            return
          }
          this.$confirm('是否确认删除门店', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            customClass: 'dialog-class',
            center: true,
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true;
                delete_hotel({
                  hotelId: row.id
                }).then(() => {
                  this.$message.success('删除成功')
                  this.getList()
                  instance.confirmButtonLoading = false;
                  done()
                }).catch(err => {
                  instance.confirmButtonLoading = false;
                  if (err.code == 300001) {
                    if (err.data.count > 0) {
                      instance.message = err.data.message
                      instance.confirmButtonText = '去解绑'
                      instance.beforeClose = (action, instance, done) => {
                        if (action === 'confirm') {
                          this.$router.push({
                            name: 'group-list'
                          })
                        }
                        done()
                      }
                    } else if (err.data.count == 0) {
                      instance.message = err.data.message
                      instance.showConfirmButton = false
                      instance.beforeClose = (action, instance, done) => {
                        done()
                      }
                    } else if (err.data.mpCount > 0) {
                      instance.message = err.data.message
                      instance.confirmButtonText = '去删除/解绑'
                      instance.beforeClose = (action, instance, done) => {
                        if (action === 'confirm') {
                          this.$router.push({
                            name: 'minip-list'
                          })
                        }
                        done()
                      }
                    }
                  } else {
                    this.$message.error(err.msg || "请求错误");
                  }
                })
              } else {
                done()
              }
            }
          }).then(action => {
            console.log(action)
          })
          break
      }
    },
    hideRecharge() {
      this.rechargeVisible = false
      this.rechargeData = {}
    },
    confirmRecharge() {
      this.getList()
      this.hideRecharge()
    },
    hideChange() {
      this.changeVisible = false
      this.changeData = {}
    },
    confirmChange() {
      this.getList()
      this.hideChange()
    },
    showDetail(row) {
      this.detailData = row
      this.detailVisible = true
    },
    hideDetail() {
      this.detailData = {}
      this.detailVisible = false
    },
    hideLog() {
      this.hotelId = ''
      this.logVisible = false
    },
    goBack() {
      const keepAlive = this.$store.state.page.keepAlive.concat(['marketer-list'])
      this.$store.commit('page/SET_KEEPALIVE', keepAlive)
      this.$router.go(-1)
    },
    handleClear() {
      this.$confirm(`
        <div>
          <div>1、清空数据后将无法恢复，请谨慎操作</div>
          <div>2、确认清空后，清空数据功能将会置灰，不可二次操作</div>
        </div>
      `, '二次确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'dialog-class',
        dangerouslyUseHTMLString: true,
        center: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            clear_hotel_data({
              hotelId: this.dialogData.id
            }).then(() => {
              this.clearVisible = false
              this.$message.success('清空成功')
              this.getList()
              instance.confirmButtonLoading = false;
              done()
            })
          } else {
            done()
          }
        }
      }).then(action => {
        console.log(action)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.status-tag{
  font-size: 12px;
  width: 75px;
  line-height: 32px;
  display: inline-block;
  border-radius: 2px;
  cursor: pointer;
  &.status1{ // 新开户
    color: #568DC5;
    background: #EFF7FE;
  }
  &.status2{ // 营业中
    color:#64A264;
    background: #EDFBEC;
  }
  &.status3{ // 已下线
    color: #666666;
    background: #F1F1F1;
  }
  &.status4{ // 异常
    color: #B52929;
    background: #FAF1F1;
  }
}
.icon-login{
  width: 11px;
  height: 13px;
  display: inline-block;
  background: url('../../assets/icons/icon-login1.png');
  vertical-align: -2px;
}
.icon-change{
  width: 11px;
  height: 13px;
  display: inline-block;
  background: url('../../assets/icons/icon-change1.png');
  vertical-align: -2px;
}
.icon-renew{
  width: 12px;
  height: 12px;
  display: inline-block;
  background: url('../../assets/icons/icon-renew1.png');
  vertical-align: -2px;
}
::v-deep .el-dropdown-menu__item:hover{
  .icon-login{
    background: url('../../assets/icons/icon-login2.png');
  }
  .icon-change{
    background: url('../../assets/icons/icon-change2.png');
  }
  .icon-renew{
    background: url('../../assets/icons/icon-renew2.png');
  }
}
::v-deep .disabled{
  color: #bbb !important;
  background: #fff !important;
  cursor: no-drop;
  &.el-dropdown-menu__item:hover{
    .icon-login{
      background: url('../../assets/icons/icon-login1.png');
    }
  }
}
.back {
  // width: 100%;
  margin-bottom: 10px;
  background: white;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  :first-child {
    margin-right: 5px;
  }
}
.icon-below{
  background: url('../../assets/icons/below.png');
  display: inline-block;
  width: 10px;
  height: 14px;
  margin-left: 7px;
}
.icon-warning{
  background: url('../../assets/icons/icon-warning.png');
  display: inline-block;
  width: 14px;
  height: 15px;
  margin-left: 7px;
}
.icon-warning-dueTime{
  background: url('../../assets/icons/icon-warning-dueTime.png');
  background-size: 100% 100%;
  background-position: center;
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-left: 7px;
  vertical-align: -1px;
}
.c-red{
  color: #E14B38;
}
</style>