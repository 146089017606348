<template>
  <el-popover
    width="250"
    trigger="click"
    v-model="popoverVisible"
    placement="bottom-start"
    @show="showPopover()"
    @hide="hidePopover()">
    <span slot="reference">
      <el-input
        size="small" style="width: 180px"
        :value="showValue"
        readonly
        class="range-input"
      >
        <span slot="suffix" style="line-height: 32px">
          <span v-if="unit" class="mr-5">{{unit}}</span>
          <span class="icon-arrow" :class="popoverVisible ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></span>
          <span class="el-icon-circle-close" @click.stop="clear"></span>
        </span>
      </el-input>
    </span>
    <div>
      <div class="user-list">
        <div
          v-for="item in option"
          :key="item.k"
          :class="{'checked': value == item.k}"
          @click.stop="handleChange(item)"
        >
          {{item.v}}
        </div>
      </div>
      <div class="flex flex-center mt-10 mb-10">
        <el-input size="small" class="flex-1" v-model="startValue" oninput="value=value.replace(/[^\d]/g,'')" clearable @clear="handleClear('startValue')"></el-input>
        <span class="plr-10">-</span>
        <el-input size="small" class="flex-1" v-model="endValue" oninput="value=value.replace(/[^\d]/g,'')" clearable @clear="handleClear('endValue')"></el-input>
        <el-button size="small" type="text" class="ml-10" :disabled="!checkNumValid" @click.stop="handleChange()">确认</el-button>
      </div>
    </div>
  </el-popover>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    option: {
      type: Array,
      default: () => []
    },
    valueStart: {
      type: [String, Number]
    },
    valueEnd: {
      type: [String, Number]
    },
    value: {
      type: [String, Number]
    },
    unit: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      popoverVisible: false,
      showValue: '全部',
      startValue: '',
      endValue: ''
    }
  },
  watch: {
    visible(val) {
      this.popoverVisible = val
    },
    popoverVisible(val) {
      this.$emit('update:visible', val)
    },
    value(val) {
      if (!val) { // 列表重置
        this.showValue = '全部'
        this.startValue = ''
        this.endValue = ''
      }
    },
  },
  computed: {
    checkNumValid() {
      if (!this.startValue && !this.endValue) return false
      if (!(this.checkNum(this.startValue) && this.checkNum(this.endValue))) return false
      if (this.endValue && Number(this.startValue) >= Number(this.endValue)) return false
      return true
    }
  },
  methods: {
    showPopover() {

    },
    hidePopover() {
      if (this.startValue || this.endValue) {
        this.handleChange()
      } else if (this.value == -1) {
        this.clear()
      }
    },
    handleChange(item) {
      if (item) {
        this.startValue = ''
        this.endValue = ''
        this.showValue = item.v
        this.$emit('update:value', item.k)
        this.$emit('update:valueStart', item.value[0])
        this.$emit('update:valueEnd', item.value[1])
      } else {
        if (!this.checkNumValid) return this.$message.warning('请输入有效的查询范围')
        this.startValue = this.startValue || '0'
        this.showValue = this.startValue + ' - ' + this.endValue
        this.$emit('update:value', -1)
        this.$emit('update:valueStart', this.startValue)
        this.$emit('update:valueEnd', this.endValue)
      }
      this.popoverVisible = false
    },
    checkNum(val) {
      return Number(val) >= 0 && (Math.floor(val) === (+val))
    },
    clear() {
      this.startValue = ''
      this.endValue = ''
      this.showValue = '全部'
      this.$emit('update:value', '')
      this.$emit('update:valueStart', this.startValue)
      this.$emit('update:valueEnd', this.endValue)
      this.popoverVisible = false
    },
    handleClear(type) {
      if (type == 'startValue') {
        this.startValue = ''
        this.$emit('update:valueStart', this.startValue)
      }
      if (type == 'endValue') {
        this.endValue = ''
        this.$emit('update:endValue', this.endValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.range-input{
  cursor: pointer;
  ::v-deep {
    .el-input__inner{
      cursor: pointer;
    }
  }
  .el-icon-circle-close{
    display: none;
  }
  &:hover{
    .el-icon-circle-close{
      display: inline;
    }
    .icon-arrow{
      display: none;
    }
  }
}
.user-list{
  margin: 0 -12px;
  & > div{
    padding: 10px 20px;
    &:hover{
      background: #F2F6FC;
      color: $color;
      cursor: pointer;
    }
    &.checked{
      color: $color;
    }
  }
}
</style>